// Should we ever use these components on more pages, we might want to
// reconsider setting the primary and secondary color to the same color.
// However, because switches use the secondary color right now and we want them
// to use the primary color, we set the secondary color to the same as the
// primary.
// Maybe there is a way to just set the switch colors.
@use "@material/theme" with (
  $primary: #24236a,
  $secondary: #24236a,
  $on-primary: #ffffff,
  $on-secondary: #ffffff
);

// NOTE: Some typographic styles use font-weights that we don't have. For
// example, buttons have `font-weight: 500`, however we only have 400, 700 and
// 900. We could import 500 as well, but since for now we only use it on admin
// pages, I'll not import it.
@use "@material/typography" with (
  $font-family: unquote("Lato, Verdana, sans-serif"),
  $styles-body2: (
    font-size: 1.4rem,
    line-height: 2rem,
  ),
  $styles-button: (
    font-size: 1.4rem,
    font-weight: bold
      // The default is 500, but we don't have a font-weight of 500 imported.,,,,,,
  ),
  $styles-subtitle1: (
    font-size: 1.6rem,
  ),
  $styles-subtitle2: (
    font-size: 1.4rem,
    font-weight: bold,
    line-height: 2.2rem,
  )
);

//// Buttons
@use "@material/button/styles" as button-styles;
////

//// Text Fields
@use "@material/floating-label/mdc-floating-label";
@use "@material/line-ripple/mdc-line-ripple";
@use "@material/notched-outline/mdc-notched-outline";
@use "@material/textfield";
////

//// Switches
@use "@material/switch/styles" as switch-styles;
////

//// Radio Buttons
@use "@material/radio/styles";
@use "@material/form-field";
////

//// Data Tables
@use "@material/data-table/data-table";
@use "@material/data-table/data-table-theme";
////

// `@use` statements have to be before anything else, so these are not grouped
// with them. Otherwise you get this error:
// "SassError: @use rules must be written before any other rules."
@include form-field.core-styles;
@include textfield.core-styles;
@include data-table.core-styles;
@include data-table.theme-baseline;

// This is using `$on-surface` of `@material/theme`, but I am not sure what
// consequences it would have to override this. So I just set two colors on the
// table itself.
@include data-table-theme.header-row-text-color(#6d6c8c);
@include data-table-theme.row-text-color(#6d6c8c);

@mixin material-font-smoothing-fix {
  // These "fixes" are only necessary on macOS.
  // https://github.com/material-components/material-components-web/issues/248#issuecomment-288545300
  // Update 2021-12-07:
  // Set to `auto` which seems to work on both Firefox and Chrome.
  // This also only affects macOS, see https://caniuse.com/font-smooth.
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}

// Have to override this after we import the CSS files. This value is not
// affected by our variables above. We use 1rem = 10px, MC uses 16px. So we
// arrive at 1.84rem by calculating 1.15 * 16 = 18.4px or 1.84rem.
// https://github.com/material-components/material-components-web/blob/v11.0.0/packages/mdc-floating-label/_mixins.scss#L54
.mdc-floating-label {
  line-height: 1.84rem;
}

.mdc-data-table__cell,
.mdc-data-table__header-cell {
  // Vertically center contents of a table cell and header cell.
  vertical-align: middle;
  @include material-font-smoothing-fix;
}

//// NOTE: 2021-05-04: Left here for future reference.
// We use 1 rem = 10px, so we have to override MDC typography to get the
// seemingly correct results.
// $mdc-typography-styles-headline1: (
//   font-size: 9.6rem,
//   line-height: 9.6rem,
// );
// $mdc-typography-styles-headline2: (
//   font-size: 6rem,
//   line-height: 6rem,
// );
// $mdc-typography-styles-headline3: (
//   font-size: 4.8rem,
//   line-height: 5rem,
// );
// $mdc-typography-styles-headline4: (
//   font-size: 3.4rem,
//   line-height: 4rem,
// );
// $mdc-typography-styles-headline5: (
//   font-size: 2.4rem,
//   line-height: 3.2rem,
// );
// $mdc-typography-styles-headline6: (
//   font-size: 2rem,
//   font-weight: bold,
//   line-height: 3.2rem,
// );
// $mdc-typography-styles-subtitle1: (
//   font-size: 1.6rem,
//   line-height: 2.8rem,
// );
// $mdc-typography-styles-subtitle2: (
//   font-size: 1.4rem,
//   font-weight: bold,
//   line-height: 2.2rem,
// );
// $mdc-typography-styles-body1: (
//   font-size: 1.6rem,
//   line-height: 2.4rem,
// );
// $mdc-typography-styles-body2: (
//   font-size: 1.4rem,
//   line-height: 2rem,
// );
// $mdc-typography-styles-caption: (
//   font-size: 1.2rem,
//   line-height: 2rem,
// );
// $mdc-typography-styles-button: (
//   font-size: 1.4rem,
//   font-weight: bold,
//   line-height: 3.6rem,
// );
// $mdc-typography-styles-overline: (
//   font-size: 1.2rem,
//   font-weight: bold,
//   line-height: 3.2rem,
// );
