.bar {
  display: flex;
  align-items: baseline;
  border-top: #e2e1f0 0.1rem solid;
  border-right: #e2e1f0 0.1rem solid;
  border-left: #e2e1f0 0.1rem solid;
}

.group {
  margin-right: 0.5rem;
}

.button {
  background: #fff;
  font-size: inherit;
  cursor: pointer;
  color: #777;
  border-radius: 0;
  padding: 0.5rem 0.5rem;
}

.button > svg {
  display: block;
}

/*
 Disabling this should prevent buttons like the "B" to stay focused (and thus it
 looks like it's still activated) after tapped on mobile
*/
.button:hover {
  color: #24236a;
  background: #fff;
}

.button.active {
  color: #24236a;
}

.button:disabled {
  color: #ccc;
}

/* Custom, disable yellow outline on Android, maybe */
.button:focus {
  outline: 0;
}
